import "images";
import "fonts";
import "stylesheets/application";

// These polyfills ensure that modern JS features
import "core-js/stable"
import "regenerator-runtime/runtime"

import "modules/bootstrap";
import "modules/jquery-plugins";

import "modules/chart";
import "modules/cookie";
import "modules/moment";
import "modules/mask";
import "modules/calendar";
import "modules/dragdrop";
import "modules/dropdown";
import "modules/tel";

import "modules/site";

window.Rails = require("@rails/ujs");
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import "controllers";

// this needs to be after Rails.start()
// https://github.com/ifad/data-confirm-modal
require('data-confirm-modal');

dataConfirmModal.setDefaults({
  title: 'Please Confirm',
  commit: 'Ok',
  cancel: 'Cancel'
});
