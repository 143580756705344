import Cleave from "cleave.js"

const watchedPhoneInputs = {}

function watchPhoneInputs() {
  document.querySelectorAll('input[type=tel]').forEach((input, key) => {
    if (!watchedPhoneInputs[key]) {
      watchedPhoneInputs[key] = true
      new Cleave(input, {
        numericOnly: true,
        blocks: [0, 3, 0, 3, 4],
        delimiters: ["(", ")", " ", "-"]
      })
    }
  });
}


document.addEventListener("turbolinks:load", () => {
  watchPhoneInputs()

  if (typeof MutationObserver !== "undefined") {
    document.querySelectorAll('.modal').forEach(modal => {
      const observer = new MutationObserver(watchPhoneInputs)
      observer.observe(modal, {
        childList: true,
        subtree: true
      })
    })
  }
})
