import 'select2';
import ClipboardJS from "clipboard";
import "jquery-confirm";
import "jquery-validation";
import "jquery-validation/dist/additional-methods.js";
import "tablesorter";

import "script-loader!./vendor/owl.carousel.min"
import "script-loader!./vendor/responsive-calendar.js"

window.ClipboardJS = ClipboardJS;
