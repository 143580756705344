import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String, open: Boolean }

  connect() {
    this.load()
  }

  load() {
    fetch(this.urlValue)
      .then(response => response.text())
      .then(html => {
        $(this.element).find('.openings').html(html)
        this.refreshInstructorOpenings();
        if (this.openValue) {
          $(this.element).find('.openings').removeClass('d-none');
        }
      })
  }

  refreshInstructorOpenings() {
    const $container = $(this.element);
    const openingsCount = $container.find('.schedule-days').data('openingsCount');
    const openingsDays = $container.find('.schedule-days').data('openingsDays');
    const scheduledCount = $container.find('.schedule-days').data('scheduledCount');
    const scheduledDays = $container.find('.schedule-days').data('scheduledDays');

    if (Number(openingsCount) > 0 || Number(scheduledCount) > 0) {
      if ($container.find('.opening-count')) {
        let message = '';
        if (Number(scheduledCount) > 0) {
          message += `${scheduledCount} booked on ${scheduledDays}`;
        }
        if (Number(openingsCount) > 0) {
          message += `${message.length > 0 ? '<br />' : ''}${openingsCount} opening${openingsCount > 1 ? 's' : ''} on ${openingsDays}`;
        }
        $container.find('.opening-count').html(message);
      }
    } else if ($container.find('.no-data-message')) {
      $container.find('.no-data-message').removeClass('d-none');
    } else {
      $container.hide();
    }
  }

  toggleDetails(event) {
    event.preventDefault();
    $(this.element).find('.openings').toggleClass('d-none');
  }
}
