import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["categorySelect", "taxable"];

  connect() {
    // dispatch native events
    $(this.categorySelectTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });

    this.updateFormContent();
  }

  updateFormContent() {
    // reset
    $('*[class*="hide-when"]').removeClass('d-none');
    $('*[class*="d-when"]').addClass('d-none');
    // change the display/none class according to the rule.
    $(`.hide-when-${this.categorySelectTarget.value}`).addClass("d-none");
    $(`.d-when-${this.categorySelectTarget.value}`).removeClass("d-none");

    // only for the taxable to be checked when it's physical_product
    if (this.categorySelectTarget.value === "physical_product") {
      this.taxableTarget.checked = 'checked';
    } else {
      this.taxableTarget.checked = undefined;
    }
  }
}