import { Controller } from "stimulus"
import { marked } from "marked"

// Connects to data-controller="markdown"
export default class extends Controller {
  static targets = ["input", "output"]

  connect() {
    this.parse()
  }

  change() {
    this.parse()
  }

  parse() {
    this.outputTarget.innerHTML = marked.parse(this.inputTarget.value)
  }
}