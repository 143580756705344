import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["body", "templateSelect" ]

  connect() {
    // dispatch native events
    $("#message_content_template_id").on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });

  }

  resetTemplateSelection(event) {
    this.templateSelectTarget.value = null;
    $(this.templateSelectTarget).trigger('change')
  }

  updateBody(event) {
    this.bodyTarget.value = $(`#template-${event.target.value}`).data("content");
  }
}
