import { Controller } from "stimulus"
import '@stripe/stripe-js';

const stypeStyle = {
  base: {
    color: "#32325d",
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: "antialiased",
    fontSize: "16px",
    "::placeholder": {
      color: "#aab7c4"
    }
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a"
  }
};

export default class extends Controller {
  static targets = ["card", "name", "error", "form", "payButton", "payError" ]
  
  connect() {
    let stripeMeta = document.querySelector('meta[name="stripe-key"]')
    if (stripeMeta === null) { return }

    let stripeKey = stripeMeta.getAttribute("content")
    this.stripe = Stripe(stripeKey);
    let elements  = this.stripe.elements()

    // Setup Intents are used for adding new cards to be charged in the future
    this.setup_intent = this.data.get("setup-intent")

    // Payment intents are for processing payments that require action
    this.payment_intent = this.data.get("payment-intent")

    // Setup regular payments
    this.card = elements.create("card", { style: stypeStyle})
    this.card.mount(this.cardTarget)
    if (this.nameTarget.value == "") {
      this.nameTarget.value = this.data.get("username");
    }
    this.card.addEventListener("change", this.changed.bind(this))
  }

  changed(event) {
    if (event.error) {
      this.errorTarget.textContent = event.error.message
    } else {
      this.errorTarget.textContent = ""
    }
  }

  keydown(event) {
    if (event.keyCode == 13) {
      // Catch Enter key's form submission and process as submit
      event.preventDefault()
      this.submit(event)
    }
  }

  updatePayButton(event) {
    const price = $("#payment-meta").data("price-value");
    const amount = price * event.target.value;
    if(amount && amount > 0) {
      this.payButtonTarget.removeAttribute('disabled');
      this.payButtonTarget.value = `Pay $${amount} Now`;
    } else {
      this.payButtonTarget.value = "Pay Now";
      this.payButtonTarget.setAttribute('disabled', true);
    }
  }

  submit(event) {
    event.preventDefault()

    if (this.nameTarget.value == "") {
      this.errorTarget.textContent = "Name on card is required."
      return
    }

    // One time payments
    if (this.payment_intent) {
      console.log("this.payment_intent: ", this.payment_intent);
      this.handleCardPayment()

      // Updating card with setup intent
    } else if (this.setup_intent) {
      console.log("this.setup_intent: ", this.setup_intent);
      this.setupNewCard()

    // Subscriptions simply tokenize the payment method and redirect to payment page if SCA required
    } else {
      console.log("createPaymentMethod...");
      this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          name: this.nameTarget.value
        },
      }).then((result) => this.handlePaymentMethod(result.paymentMethod.id))
    }
  }

  onUpdateCardSuccess() {
    Turbolinks.clearCache()
    window.location.reload();
  }

  hideModal() {
    $('#global-modal').modal('hide');
  }

  setupNewCard() {
    let data = {
      payment_method: {
        card: this.card,
        billing_details: {
          name: this.nameTarget.value
        }
      }
    }

    this.stripe.confirmCardSetup(this.setup_intent, data).then((result) => {
      if (result.error) {
        this.errorTarget.textContent = result.error.message
      } else {
        this.handlePaymentMethod(result.setupIntent.payment_method)
      }
    })
  }

  handlePaymentMethod(payment_method_id) {
    // this.addHiddenField("user[processor]", "stripe")
    this.addHiddenField("user[card_token]", payment_method_id)

    Rails.fire(this.formTarget, "submit")
  }

  addHiddenField(name, value) {
    let hiddenInput = document.createElement("input")
    hiddenInput.setAttribute("type", "hidden")
    hiddenInput.setAttribute("name", name)
    hiddenInput.setAttribute("value", value)
    this.formTarget.appendChild(hiddenInput)
  }

  handleCardPayment() {
    // Handle an existing payment that needs confirmation
    this.stripe.confirmCardPayment(this.payment_intent).then((result) => {
      if (result.error) {
        this.errorTarget.textContent = result.error.message

      } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        $.ajax({
          url: "/handle_order",
          method: "post",
          data: { 
            "pi_id": result.paymentIntent.id 
          }
        })
      }
    })
  }
  
  onPurchaseSuccess(event) {
    const [data] = event.detail;
    if (data.new_billing) {
      this.handleNewBillingLogic(data);
    } else {
      this.handleOldPurchaseLogic(data);
    }
  }

  handleNewBillingLogic(data) {
    if (data.payment_intent) {
      this.payment_intent = data.payment_intent;
      this.handleCardPayment();
    } else if (data.error) {
      this.payErrorTarget.textContent = data.error;
    } else if (data.redirect_to) {
      setTimeout(() => {
        // disable the submit button after disabled_with ends, there is 
        // short duration there is a chance to double click the button
        this.payButtonTarget.value = 'Payment Finished';
        this.payButtonTarget.setAttribute('disabled', true);
      }, 0);

      window.location.href = data.redirect_to;
    } else {
      Turbolinks.clearCache()
      window.location.reload();
    };
  }

  handleOldPurchaseLogic(data) {
    if (data.payment_intent) {
      this.payment_intent = data.payment_intent;
      this.handleCardPayment();
    } else if (data.error) {
      this.errorTarget.textContent = data.error;
    } else if (data.message) {
      setTimeout(() => {
        // disable the submit button after disabled_with ends, there is 
        // short duration there is a chance to double click the button
        this.payButtonTarget.value = 'Payment Finished';
        this.payButtonTarget.setAttribute('disabled', true);
      }, 0);

      if (data.same_day) {
        $('#same-day-assign-time-slot').trigger('click');
      } else {
        Turbolinks.clearCache()
        window.location.reload();
      }
    }
  }
}
