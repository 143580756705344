$.delete = function(url, data, callback, type){
  if ( $.isFunction(data) ){
    type = type || callback,
        callback = data,
        data = {}
  }

  return $.ajax({
    url: url,
    type: 'DELETE',
    success: callback,
    data: data,
    contentType: type
  });
}

$.put = function(url, data, callback, type){
  if ( $.isFunction(data) ){
    type = type || callback,
        callback = data,
        data = {}
  }

  return $.ajax({
    url: url,
    type: 'PUT',
    success: callback,
    data: data,
    contentType: type
  });
}

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="toggle"]').bootstrapToggle();

  $('.tablesort').tablesorter();

  // set the sticky offset based on the header height
  calendarInit();

  function loadResponseIntoModal(element, e) {
    var name = element.data('title');
    var url = element.data('url') || '';
    var method = element.data('type') || 'get';
    var data = element.serialize();

    var $ajaxRequest = $.get;
    if (method === 'post') {
      $ajaxRequest = $.post
    } else if(method == 'delete') {
      $ajaxRequest = $.delete
    } else if(method == 'put') {
      $ajaxRequest = $.put
    }

    $ajaxRequest(url, data, function (html) {
      if(html == 'invalid_request') {
        location.reload();
      } else {
        var modal = $('#global-modal');
        modal.find('.modal-title').html(name);
        modal.find('.modal-body').html(html);
        modal.modal("show");
      }
    });
  };

  $(document).on('click', 'a[data-trigger="remote-modal"]', function (e) {
    e.preventDefault();
    loadResponseIntoModal($(this), e);
  });

  $(document).on('submit', 'form[data-trigger="remote-modal"]', function (e) {
    e.preventDefault();
    loadResponseIntoModal($(this), e);
  });

  $(document).on("click", "[data-behavior=show-waitlist-form]", function(event) {
    event.preventDefault();
    var url = $(this).data('url') || '';
    $.get(url, function(html) {
      var modal = $('#global-modal');
      modal.find('.modal-title').html('Waitlist Settings');
      modal.find('.modal-body').html(html);
      modal.modal();

      modal.on('click', 'input#instructors_all', function() {
        $('.instructor_list').addClass('d-none');
        $('.instructor_list input').prop('checked', false);
      });

      modal.on('click', 'input#instructors_specific', function() {
        $('.instructor_list').removeClass('d-none');
      });

    });
  });

  $("form.validate").each(function () {
    $(this).validate({
      errorPlacement: function (error, element) {
        if (element.attr("name") == "user[i_agree]") {
          error.appendTo(".i-accept-wrapper");
        } else {
          error.insertAfter(element);
        }
      }
    });

    $('#user_password_confirmation').rules('add', {
      equalTo: '#user_password'
    })
  });

  $.validator.addMethod("pwcheck", function (value) {
    if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,20}$/.test(value)) {
      return true;
    } else {
      if (value.length <= 8) {
        $.validator.messages.pwcheck = "Passwords must include at least 8 characters."
      } else {
        $.validator.messages.pwcheck = "Passwords must include at least 1 uppercase letter, 1 lowercase letter, symbol, and number."
      }
      return false;
    }
  });

  $(".mask-phone").mask("(999) 999-9999");
  $(".mask-dob").mask("99/99/9999");

  $('.filter-select').on('change', function (e) {
    window.location.href = `${$(this).data('filter-url')}?filter=${$(this).val()}&s=${$(this).data('s')}`;
  });

  if (window.matchMedia("(max-width: 767px)").matches) {
    console.log('Mobile = TRUE');
    window.Cookies.set('on_mobile', 'true')
    //document.cookie = 'on_mobile=true;';
  } else {
    console.log('Mobile = FALSE');
    window.Cookies.remove('on_mobile', { path: '/' });
    //document.cookie = 'on_mobile=; expires=Wed, 24 Jan 1979 00:00:00 UTC; path=/;';
  }
});

// fixed the first load sticky height issue and regarding event problem.
$(window).on('load', () => {
  calendarInit();
})

function scrollCalendar(scrollDirection) {
  const tableBody = document.querySelector('.fixed-table-body');
  const scrollAmount = document.querySelector('#scheduler-table th:not(.time-column)').clientWidth + 1;

  tableBody.scrollBy({
    top: 0,
    left: scrollDirection == 'left' ? -scrollAmount : scrollAmount,
    behavior: 'smooth'
  });
}

function calendarInit() {
  let navHeight = $('nav').height();;
  $('#scheduler-table').bootstrapTable('destroy')
  $('#scheduler-table').bootstrapTable({
    stickyHeader: true,
    stickyHeaderOffsetY: navHeight,
    fixedNumber: 1,
    fixedColumns: true
  });

  window.dispatchEvent(new Event('resize'));

  let table = document.querySelector('#scheduler-table');
  let content = document.querySelector('#scheduler-content');

  // Only show the scroll left/right arrows when they are needed
  if( table && content && table.clientWidth <= content.clientWidth ) {
    $('.scroll-left, .scroll-right').hide();
  }

  $('.scroll-left').on('click', function(){
    scrollCalendar('left');
  });

  $('.scroll-right').on('click', function(){
    scrollCalendar('right');
  });

  $(".draggable").draggable({ handle: ".handle", revert: "invalid" });
  $(".droppable").droppable({
    activeClass: "ui-state-default",
    hoverClass: "ui-state-hover",
    drop: function (event, ui) {
      var studentId = $(ui.draggable).data('student_id');
      var oldTimeSlotId = $(ui.draggable).data('time_slot_id');
      var newTimeSlotId = $(event.target).data('time_slot_id');
      $('#form_student_id').val(studentId);
      $('#form_old_time_slot_id').val(oldTimeSlotId);
      $('#form_new_time_slot_id').val(newTimeSlotId);
      // $('#switch-time-form').submit() doesn't work.
      $('#submit-btn').trigger('click');
    }
  });

  $('.common-select2').select2({
    theme: 'bootstrap4'
  });

}
