import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toggleable", "cardInfo" ]

  connect() {
    this.toggleClass = this.data.get("class") || "d-none"
    let stripeMeta = document.querySelector('meta[name="stripe-key"]')
    if (stripeMeta) { 
      let stripeKey = stripeMeta.getAttribute("content")
      this.stripe = Stripe(stripeKey);
    }
  }

  toggle(event) {
    event.preventDefault()

    this.toggleableTargets.forEach( target => {
      target.classList.toggle(this.toggleClass)
    })
  }

  onUpdateCardSuccess(event) {
    const [data, status, xhr] = event.detail;
    this.cardInfoTarget.textContent = data.text;
    this.toggle(event);
  }
}
