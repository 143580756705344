import { Controller } from "stimulus"
import { marked } from "marked"

// Connects to data-controller="markdown-display"

export default class extends Controller {
  static values = { description: String }

  connect() {
    this.element.innerHTML = marked.parse(this.descriptionValue)
  }
}