import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "planSelect", 
    "depositRequired", 
    "finalPaymentRequired",
    "scheduleItems",
    "scheduleItemTemplate",
    "paymentType",
    "amountType",
    "scheduledPaymentsError"
  ];

  connect() {
    this.updateFormContent();
  }

  submit(e) {
    const validator = this.validateForm();
    if (!validator.isValid) {
      e.preventDefault()
      $("#scheduled-items-error").removeClass("d-none")
      this.scheduledPaymentsErrorTarget.innerHTML = validator.message
    }
  }

  validateForm() {
    if (this.planSelectTarget.value === "onetime") {
      return { isValid: true }
    }

    const depositInputs = this.paymentTypeTargets.filter((t) => t.value === "deposit")
    const finalPaymentInputs = this.paymentTypeTargets.filter((t) => t.value === "final_payment")
    const percentageInputs = this.amountTypeTargets.filter((t) => t.value === "percentage")
    const fixedAmountInputs = this.amountTypeTargets.filter((t) => t.value === "fixed_amount")

    let totalNumber = 0;
    percentageInputs.forEach((input) => {
      const amount = $(input).parent().parent().find(".amount")[0]
      if(amount) {
        totalNumber += +amount.value
      }
    }) 

    if (this.depositRequiredTarget.checked && depositInputs.length === 0) {
      return { isValid: false, message: "You must have at least one scheduled payment with 'Deposit' type" }
    }

    if (this.finalPaymentRequiredTarget.checked && finalPaymentInputs.length === 0) {
      return { isValid: false, message: "You must have at least one scheduled payment with 'Final Payment' type" }
    }

    if (depositInputs.length > 1) {
      return { isValid: false, message: "You can not have more than 1 scheduled payment with 'Deposit' type" }
    }

    if (finalPaymentInputs.length > 1) {
      return { isValid: false, message: "You can not have more than 1 scheduled payment with 'Final Payment' type" }
    }

    if (percentageInputs.length > 0 && totalNumber !== 100) {
      return { isValid: false, message: "All scheduled payments with 'percentage' type should sum upto 100" }
    }

    if (fixedAmountInputs.length === 0 && percentageInputs.length ===0) {
      return { isValid: false, message: "You must have at least one scheduled payment" }
    }

    return { isValid: true }
  }

  updateFormContent() {
    // reset
    $("#scheduled-items-error").addClass("d-none");
    $('*[class*="d-when"]').addClass('d-none');
    // change the display/none class according to the rule.
    $(`.d-when-${this.planSelectTarget.value}`).removeClass("d-none");
  }

  addItem(e) {
    e.preventDefault()
    $("#scheduled-items-error").addClass("d-none");
    
    const content = this.scheduleItemTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
    this.scheduleItemsTarget.insertAdjacentHTML('beforebegin', content)
  }

  removeItem(e) {
    e.preventDefault()

    const wrapper = e.target.closest(".nested-form-wrapper")

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      $(wrapper).removeClass("d-flex").addClass('d-none')

      const input = wrapper.querySelector("input[name*='_destroy']")
      input.value = '1'
    }
  }
}